import Cookies from "js-cookie";

const cookieName = "es-active-id";

const never = new Date(2147483647000);

export const read = (): string | null => {
  const cookieValue = Cookies.get(cookieName);
  return cookieValue ? cookieValue : null;
};

export const createAttributes = (domain: string): Cookies.CookieAttributes => ({
  path: "/",
  expires: never,
  secure: true,
  sameSite: "None",
  domain,
});

export const write = (userId: string | number | null): void => {
  if (userId === null) return;

  if (window.location.host.includes("localhost")) {
    Cookies.set(cookieName, `${userId}`, createAttributes("localhost"));
  }
  if (window.location.host.includes("equipmentshare")) {
    Cookies.set(cookieName, `${userId}`, createAttributes(".equipmentshare.com"));
  }
  if (window.location.host.includes("estrack")) {
    Cookies.set(cookieName, `${userId}`, createAttributes(".estrack.com"));
  }
};

export const remove = (): void => {
  if (window.location.host.includes("localhost")) {
    Cookies.remove(cookieName, createAttributes("localhost"));
  }
  if (window.location.host.includes("equipmentshare")) {
    Cookies.remove(cookieName, createAttributes(".equipmentshare.com"));
  }
  if (window.location.host.includes("estrack")) {
    Cookies.remove(cookieName, createAttributes(".estrack.com"));
  }
};
