import { useMutation } from 'react-query'
import { Entity, EntityContactTypeIds } from '../models'
import { createEntityContact, EntityContactData } from './entity_contacts'
import {
	createEntityContactXref,
	deleteEntityContactXref,
} from './entity_contact_xrefs'
import {
	createEntityLocation,
	EntityLocationData,
	updateEntityLocation,
} from './entity_locations'
import * as thisModule from './entities'
import { compareBusinessAddress } from '../utils/compareBusinessAddress'

type EntityData = Pick<
	Entity,
	'entity_type_id' | 'name' | 'ein' | 'business_address_id'
>;

type UpdateEntityData = {
	entity_id: number,
	entity_type_id: number,
	name: string,
	ein?: number | undefined,
	business_address_id: number | null,
};

type EntityWithPrimaryContactAndBusinessAddressData = {
	entity: Omit<EntityData, 'business_address_id'>,
	businessAddress: EntityLocationData,
	primaryContact: EntityContactData | number,
};

type PatchEntityWithPrimaryContactAndBusinessAddressData = {
	oldData: {
		entity: Entity,
	},
	newData: {
		entity: Omit<EntityData, 'business_address_id'>,
		businessAddress: EntityLocationData,
		primaryContact: EntityContactData | number,
	},
};

export const createEntity = async(
	host: string,
	accessToken: string,
	entityData: EntityData,
): Promise<Entity> => {
	const response = await fetch( `${host}/entities/`, {
		method: 'POST',
		body: JSON.stringify( {
			entity_type_id: entityData.entity_type_id,
			active: true,
			name: entityData.name,
			is_customer: false,
			ein: entityData.ein,
			is_vendor: true,
			business_address_id: entityData.business_address_id,
		} ),
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const entity = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entity )
		throw new Error( `RequestLogicalError:createEntity:${response.status}:${body}` )
	}

	return entity
}

export const updateEntity = async(
	host: string,
	accessToken: string,
	entityData: UpdateEntityData,
): Promise<Entity> => {
	const response = await fetch( `${host}/entities/${entityData.entity_id}`, {
		method: 'PATCH',
		body: JSON.stringify( {
			entity_type_id: entityData.entity_type_id,
			name: entityData.name,
			ein: entityData.ein,
			business_address_id: entityData.business_address_id,
		} ),
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const entity = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entity )
		throw new Error( `RequestLogicalError:updateEntity:${response.status}:${body}` )
	}

	return entity
}

export const createEntityWithPrimaryContactAndBusinessAddress = async(
	host: string,
	accessToken: string,
	data: EntityWithPrimaryContactAndBusinessAddressData,
): Promise<Entity> => {
	const businessAddress = await createEntityLocation(
		host,
		accessToken,
		data.businessAddress,
	)
	const entity = await thisModule.createEntity( host, accessToken, {
		...data.entity,
		business_address_id: businessAddress.entity_location_id,
	} )

	let primaryContact = data.primaryContact

	if ( typeof primaryContact !== 'number' ) {
		primaryContact = (
			await createEntityContact( host, accessToken, primaryContact )
		).entity_contact_id
	}

	await createEntityContactXref( host, accessToken, {
		entity_id: entity.entity_id,
		entity_contact_id: primaryContact,
		entity_contact_type_id: EntityContactTypeIds.PRIMARY_CONTACT,
	} )

	return entity
}

export const updateEntityWithPrimaryContactAndBusinessAddress = async(
	host: string,
	accessToken: string,
	data: PatchEntityWithPrimaryContactAndBusinessAddressData,
): Promise<Entity> => {
	// check if the business address changed. If so, update the business address
	// if not, use the old business address
	const businessAddress = data.oldData.entity.business_address
		? !compareBusinessAddress(
			data.oldData.entity.business_address,
			data.newData.businessAddress,
		  )
			? await updateEntityLocation( host, accessToken, {
				...data.newData.businessAddress,
				entity_location_id:
						data.oldData.entity.business_address.entity_location_id,
			  } )
			: data.oldData.entity.business_address
		: null
	// check if the primary contact changed. If so, update the cross ref
	let primaryContact = data.newData.primaryContact
	primaryContact =
		typeof primaryContact !== 'number'
			? ( await createEntityContact( host, accessToken, primaryContact ) )
				.entity_contact_id
			: primaryContact
	if (
		primaryContact !== data.oldData.entity.primary_contact?.entity_contact_id
	) {
		// if old primary contact, remove that xref
		if ( data.oldData.entity.primary_contact?.entity_contact_id ) {
			await deleteEntityContactXref( host, accessToken, {
				entity_id: data.oldData.entity.entity_id,
				entity_contact_id:
					data.oldData.entity.primary_contact.entity_contact_id,
			} )
		}
		// add new primary contact xref
		await createEntityContactXref( host, accessToken, {
			entity_id: data.oldData.entity.entity_id,
			entity_contact_id: primaryContact,
			entity_contact_type_id: EntityContactTypeIds.PRIMARY_CONTACT,
		} )
	}

	const updateBody = {
		entity_id: data.oldData.entity.entity_id,
		entity_type_id: data.newData.entity.entity_type_id,
		name: data.newData.entity.name,
		ein: data.newData.entity.ein ? data.newData.entity.ein : undefined,
		business_address_id: businessAddress
			? businessAddress.entity_location_id
			: null,
	}

	return await thisModule.updateEntity( host, accessToken, updateBody )
}

export const setActiveForEntity = async( host: string, accessToken: string, entityId: number, isActive: boolean ): Promise<Entity> => {
	const response = await fetch( `${host}/entities/${entityId}`, {
		method: 'PATCH',
		body: JSON.stringify( { active: isActive } ),
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const entity = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entity )
		throw new Error( `RequestLogicalError:setActiveForEntity:${response.status}:${body}` )
	}

	return entity
}

export const deactivateEntity = async( host: string, accessToken: string, entityId: number ): Promise<Entity> => await thisModule.setActiveForEntity( host, accessToken, entityId, false )
export const activateEntity = async( host: string, accessToken: string, entityId: number ): Promise<Entity> => await thisModule.setActiveForEntity( host, accessToken, entityId, true )

export function createEntityMutation( host: string, accessToken: string ) {
	return useMutation(
		[ 'createEntity', host, accessToken ],
		async( entityData: EntityData ) => await createEntity( host, accessToken, entityData ),
	)
}

export function updateEntityMutation( host: string, accessToken: string ) {
	return useMutation(
		[ 'updateEntity', host, accessToken ],
		async( entityData: UpdateEntityData ) => await updateEntity( host, accessToken, entityData ),
	)
}

export function createEntityWithPrimaryContactAndBusinessAddressMutation(
	host: string,
	accessToken: string,
) {
	return useMutation(
		[ 'createEntityWithPrimaryContactAndBusinessAddress', host, accessToken ],
		async( data: EntityWithPrimaryContactAndBusinessAddressData ) => await createEntityWithPrimaryContactAndBusinessAddress(
			host,
			accessToken,
			data,
		),
	)
}

export function updateEntityWithPrimaryContactAndBusinessAddressMutation(
	host: string,
	accessToken: string,
) {
	return useMutation(
		[ 'updateEntityWithPrimaryContactAndBusinessAddress', host, accessToken ],
		async( data: PatchEntityWithPrimaryContactAndBusinessAddressData ) => await updateEntityWithPrimaryContactAndBusinessAddress(
			host,
			accessToken,
			data,
		),
	)
}

export function deactivateEntityMutation(
	host: string,
	accessToken: string,
) {
	return useMutation(
		[ 'deactivateEntity', host, accessToken ],
		async( entityId: number ) => await deactivateEntity(
			host,
			accessToken,
			entityId,
		),
	)
}

export function activateEntityMutation(
	host: string,
	accessToken: string,
) {
	return useMutation(
		[ 'activateEntity', host, accessToken ],
		async( entityId: number ) => await activateEntity(
			host,
			accessToken,
			entityId,
		),
	)
}
