import React, { MouseEvent, KeyboardEvent } from 'react';
import { User } from '../../types/user';
import { ApiUserGrant } from '../../types/identity';
import styled from 'styled-components';
import { AnimatedSkeletonLoader } from '../animated-skeleton-loader/animated-skeleton-loader';
import {
	EuiAvatar,
	EuiShowFor,
	EuiButton,
	useEuiTheme,
	TYPEWEIGHT,
	SIZE,
	TYPESIZING,
	COLORSLIGHT,
} from '@equipmentshare/ds2';

export type AccountTriggerProps = {
	readonly onClick?: (
		event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
	) => void;
	readonly user?: User;
	readonly userGrant?: ApiUserGrant;
};

const AccountDrawerWrapper = styled(EuiButton)`
	max-width: 12vw;
	min-width: initial;
	height: 36px;
	padding: 0 10px;
	& > .euiButtonContent.euiButton__content {
		padding: 0;
	}
	&:hover {
		text-decoration: none !important;
		.companyName {
			text-decoration: underline;
		}
	}
`;

const AccountDrawerInitials = styled(EuiAvatar)`
	font-size: 10px;
	text-transform: uppercase;
`;

const AccountDrawerName = styled.span`
	margin-left: ${SIZE.s};
	color: ${COLORSLIGHT.darkestShade};
	text-transform: uppercase;
	font-weight: ${TYPEWEIGHT.regular};
	${TYPESIZING.xs};
`;

export const AccountTrigger = ({
	onClick,
	user,
	userGrant,
}: AccountTriggerProps) => {
	const userDisplayName = userGrant
		? userGrant.user_name
		: `${user?.first_name} ${user?.last_name}`.trim();
	const userCompanyName = userGrant
		? userGrant?.company_name
		: user?.company.name;
	const hasUser = userGrant ?? user;
	const { euiTheme } = useEuiTheme();

	return (
		<AccountDrawerWrapper color="ghost" onClick={onClick} isLoading={!hasUser}>
			{hasUser ? (
				<AccountDrawerInitials
					name={userDisplayName}
					initialsLength={2}
					color={euiTheme.colors.darkShade}
					size="s"
					data-testid="user-initials"
				/>
			) : null}
			<EuiShowFor sizes={['m', 'l', 'xl']}>
				{hasUser ? (
					<AccountDrawerName data-testid="company-name" className="companyName">
						{userCompanyName}
					</AccountDrawerName>
				) : (
					<AnimatedSkeletonLoader $width={40} $height={15} />
				)}
			</EuiShowFor>
		</AccountDrawerWrapper>
	);
};

AccountTrigger.displayName = 'AccountTrigger';
