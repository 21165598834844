import { useMutation, useQueryClient } from 'react-query'
import { Entity, EntityVendorSettings } from '../models'

export type EntityVendorSettingsData = Pick<
	EntityVendorSettings,
	'entity_id' | 'entity_net_terms_id' | 'credit_limit' | 'external_erp_vendor_ref' | 'w9_on_file' | 'notes'
>

type PatchEntityVendorSettingsData = Pick<EntityVendorSettings, 'entity_vendor_settings_id'> & Partial<Omit<EntityVendorSettingsData, 'entity_id'>>

const isPatchEntityVendorSettingsData = ( vendorSettings: EntityVendorSettingsData | PatchEntityVendorSettingsData ): vendorSettings is PatchEntityVendorSettingsData => (
	!isNaN( ( vendorSettings as PatchEntityVendorSettingsData ).entity_vendor_settings_id )
)

export const createEntityVendorSettings = async(
	host: string,
	accessToken: string,
	data: EntityVendorSettingsData,
): Promise<EntityVendorSettings> => {
	const response = await fetch( `${host}/entity-vendor-settings/`, {
		method: 'POST',
		body: JSON.stringify( {
			entity_id: data.entity_id,
			entity_net_terms_id: data.entity_net_terms_id,
			credit_limit: data.credit_limit,
			external_erp_vendor_ref: data.external_erp_vendor_ref,
			w9_on_file: data.w9_on_file,
			notes: data.notes,
		} ),
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const entityVendorSettings = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entityVendorSettings )
		throw new Error( `RequestLogicalError:createEntityVendorSettings:${response.status}:${body}` )
	}

	return entityVendorSettings
}

export const updateEntityVendorSettings = async(
	host: string,
	accessToken: string,
	entityVendorSettingsId: number,
	data: PatchEntityVendorSettingsData,
): Promise<EntityVendorSettings> => {
	const response = await fetch(
		`${host}/entity-vendor-settings/${entityVendorSettingsId}`,
		{
			method: 'PATCH',
			body: JSON.stringify( {
				entity_net_terms_id: data.entity_net_terms_id,
				credit_limit: data.credit_limit,
				external_erp_vendor_ref: data.external_erp_vendor_ref,
				w9_on_file: data.w9_on_file,
				notes: data.notes,
			} ),
			headers: new Headers( {
				'Authorization': `Bearer ${accessToken}`,
				'content-type': 'application/json',
			} ),
		},
	)
	const updatedEntityVendorSettings = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( updatedEntityVendorSettings )
		throw new Error( `RequestLogicalError:updateEntityVendorSettings:${response.status}:${body}` )
	}

	return updatedEntityVendorSettings
}

export function upsertEntityVendorSettingsMutation(
	host: string,
	accessToken: string,
) {
	const queryClient = useQueryClient()

	return useMutation(
		[ 'upsertEntityVendorSettings', host, accessToken ],
		async( data: PatchEntityVendorSettingsData | EntityVendorSettingsData ) => await (
			 isPatchEntityVendorSettingsData( data )
			  ? updateEntityVendorSettings( host, accessToken, data.entity_vendor_settings_id, data )
			  : createEntityVendorSettings( host, accessToken, data ) ),
		{
			onSuccess: ( data ) => {
				queryClient.setQueryData<Entity | undefined>(
					[ `getEntity/entityId:${data.entity_id}`, host, accessToken ],
					( oldData ) => oldData && { ...oldData, entity_vendor_settings: data },
				)
			},
		},
	)
}
