import React, { useState } from 'react';
import styled from 'styled-components';
import {
	EuiPopover,
	EuiPopoverTitle,
	EuiSideNavItemType,
	EuiToolTip,
} from '@equipmentshare/ds2';

import { SideNavLink } from './side-nav-link';
import { SideNavSubitems } from './side-nav-subitems';

const SubitemsTitle = styled(EuiPopoverTitle)`
	line-height: 1;
`;

export type SideNavItemProps = {
	item: EuiSideNavItemType<{}>;
	navIsOpen?: boolean;
};

type NavItemPopoverProps = {
	'aria-label': string;
	button: NonNullable<React.ReactNode>;
	children: React.ReactNode;
};

const NavItemPopover = ({
	'aria-label': ariaLabel,
	button,
	children,
}: NavItemPopoverProps) => {
	const [isPopoverShowing, setIsPopoverShowing] = useState(false);

	return (
		<EuiPopover
			aria-label={ariaLabel}
			anchorPosition="rightUp"
			button={button}
			closePopover={() => setIsPopoverShowing(false)}
			hasArrow={false}
			isOpen={isPopoverShowing}
			onMouseEnter={() => setIsPopoverShowing(true)}
			onMouseLeave={() => setIsPopoverShowing(false)}
			ownFocus={false}
			panelPaddingSize="s"
		>
			{children}
		</EuiPopover>
	);
};

export const SideNavItem = ({ item, navIsOpen }: SideNavItemProps) => {
	const navLink = <SideNavLink item={item} navIsOpen={navIsOpen} />;

	if (item.items) {
		const hasSelectedChild = item.items.some((child) => child.isSelected);

		if (navIsOpen && (item.isSelected || hasSelectedChild)) {
			return (
				<>
					{navLink}
					<SideNavSubitems items={item.items} navIsOpen={navIsOpen} />
				</>
			);
		}

		return (
			<NavItemPopover button={navLink} aria-label={`${item.name} links`}>
				{!navIsOpen && (
					<SubitemsTitle paddingSize="m">{item.name}</SubitemsTitle>
				)}
				<SideNavSubitems items={item.items} />
			</NavItemPopover>
		);
	}

	return navIsOpen ? (
		navLink
	) : (
		<EuiToolTip position="right" content={item.name}>
			{navLink}
		</EuiToolTip>
	);
};
