import React from 'react';
import styled from 'styled-components';
import { SIZE } from '@equipmentshare/ds2';

const SideNavTriggerIcon = styled.div`
	transition-timing-function: linear;
	transition-duration: 0.15s;
	transition-property: filter;
	height: ${SIZE.l};
	display: flex;
	align-items: center;
	transform: translateY(${SIZE.xxs});

	&.isOpen:hover {
		.inner:before {
			transform: translate3d(-${SIZE.xs}, 3px, 0) rotate(-45deg) scaleX(0.6);
		}
		.inner:after {
			transform: translate3d(-${SIZE.xs}, -3px, 0) rotate(45deg) scaleX(0.6);
		}
	}

	&:not(.isOpen):hover .inner:before {
		transform: translate3d(6px, 3px, 0) rotate(45deg) scaleX(0.6);
	}
	&:not(.isOpen):hover .inner:after {
		transform: translate3d(6px, -3px, 0) rotate(-45deg) scaleX(0.6);
	}
`;

const SideNavTriggerIconBox = styled.div`
	position: relative;
	display: flex;
	width: 18px;
	height: 10px;
`;
const SideNavTriggerIconInner = styled.div`
	top: 50%;
	display: block;

	&,
	&:after,
	&:before {
		position: absolute;
		width: ${SIZE.base};
		height: ${SIZE.xxs};
		transition-timing-function: ease;
		transition-duration: 0.15s;
		transition-property: transform;
		border-radius: ${SIZE.xs};
		background-color: black;
	}
	&:after,
	&:before {
		display: block;
		content: '';
	}
	&:before {
		top: -6px;
	}
	&:after {
		bottom: -6px;
	}
`;

export type SideNavTriggerProps = {
	readonly open?: Boolean;
};

export const SideNavTrigger = ({ open }: SideNavTriggerProps) => {
	return (
		<SideNavTriggerIcon className={open ? 'isOpen' : ''}>
			<SideNavTriggerIconBox>
				<SideNavTriggerIconInner className="inner" />
			</SideNavTriggerIconBox>
		</SideNavTriggerIcon>
	);
};
