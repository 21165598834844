import { useMutation } from 'react-query'
import { EntityContact } from '../models'
import { createEntityContactXref } from './entity_contact_xrefs'

export type EntityContactData = Pick<
	EntityContact,
	'first_name' | 'last_name' | 'phone_number' | 'email_address'
>;

export type UpdateEntityContactData = Pick<
	EntityContact,
	| 'first_name'
	| 'last_name'
	| 'phone_number'
	| 'email_address'
	| 'entity_contact_id'
>;

type EntityContactWithEntityData = {
	entity_id: number,
	entity_contact: EntityContactData,
	entity_contact_type_id: number,
};

export const createEntityContact = async(
	host: string,
	accessToken: string,
	entityContactData: EntityContactData,
): Promise<EntityContact> => {
	const response = await fetch( `${host}/entity-contacts/`, {
		method: 'POST',
		body: JSON.stringify( {
			first_name: entityContactData.first_name,
			last_name: entityContactData.last_name,
			phone_number: entityContactData.phone_number,
			email_address: entityContactData.email_address,
		} ),
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const entityContact = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entityContact )
		throw new Error( `RequestLogicalError:createEntityContact:${response.status}:${body}` )
	}

	return entityContact
}

export const createEntityContactForEntity = async(
	host: string,
	accessToken: string,
	data: EntityContactWithEntityData,
): Promise<EntityContact> => {
	const entityContact = await createEntityContact( host, accessToken, data.entity_contact )

	await createEntityContactXref( host, accessToken, {
		entity_id: data.entity_id,
		entity_contact_id: entityContact.entity_contact_id,
		entity_contact_type_id: data.entity_contact_type_id,
	} )

	return entityContact
}

export const updateEntityContact = async(
	host: string,
	accessToken: string,
	entityContactData: UpdateEntityContactData,
): Promise<EntityContact> => {
	const response = await fetch(
		`${host}/entity-contacts/${entityContactData.entity_contact_id}`,
		{
			method: 'PATCH',
			body: JSON.stringify( {
				first_name: entityContactData.first_name,
				last_name: entityContactData.last_name,
				phone_number: entityContactData.phone_number,
				email_address: entityContactData.email_address,
			} ),
			headers: new Headers( {
				'Authorization': `Bearer ${accessToken}`,
				'content-type': 'application/json',
			} ),
		},
	)
	const entityContact = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entityContact )
		throw new Error( `RequestLogicalError:updateEntityContact:${response.status}:${body}` )
	}

	return entityContact
}

export function createEntityContactMutation( host: string, accessToken: string ) {
	return useMutation(
		[ 'createEntityContact', host, accessToken ],
		async( entityContactData: EntityContactData ) => await createEntityContact( host, accessToken, entityContactData ),
	)
}

export function updateEntityContactMutation( host: string, accessToken: string ) {
	return useMutation(
		[ 'updateEntityContact', host, accessToken ],
		async( entityContactData: UpdateEntityContactData ) => await updateEntityContact( host, accessToken, entityContactData ),
	)
}

export function createEntityContactForEntityMutation( host: string, accessToken: string ) {
	return useMutation(
		[ 'createEntityContactForEntity', host, accessToken ],
		async( entityContactData: EntityContactWithEntityData ) => await createEntityContactForEntity( host, accessToken, entityContactData ),
	)
}
