import { createCustomClaimGetter } from "./token-claims";

import type { Auth0ContextInterface } from "../../auth0/context-modifier";
import type { Identity } from "../identity";

/**
 * @see https://auth0.com/docs/security/tokens/json-web-tokens/json-web-token-claims#custom-claims
 */
type CustomProps = {
  readonly es_identity_id: string;
  readonly es_user_id: number;
  readonly es_user_name: string;
  readonly es_user_email: string;
  readonly es_company_id: number;
  readonly es_company_name: string;
  readonly es_security_level_id: number;
  readonly es_read_only: boolean;
};

export type IdentityFromIdToken = Pick<
  Identity,
  | "userId"
  | "companyId"
  | "companyName"
  | "securityLevelId"
  | "identityId"
  | "readOnly"
  | "fullName"
  | "email"
  | "idToken"
>;

const getCustomClaim = createCustomClaimGetter<CustomProps>();

export const extractIdentityFromIdToken = async (
  auth0: Auth0ContextInterface
): Promise<Partial<IdentityFromIdToken>> => {
  const claims = await auth0.getIdTokenClaims();

  if (!claims) {
    throw new Error("Could not resolve the id token");
  }

  return {
    userId: getCustomClaim(claims, "es_user_id"),
    companyId: getCustomClaim(claims, "es_company_id"),
    securityLevelId: getCustomClaim(claims, "es_security_level_id"),
    identityId: getCustomClaim(claims, "es_identity_id"),
    companyName: getCustomClaim(claims, "es_company_name"),
    readOnly: getCustomClaim(claims, "es_read_only"),
    fullName: getCustomClaim(claims, "es_user_name"),
    email: getCustomClaim(claims, "es_user_email"),
    idToken: claims.__raw,
  };
};
