import * as React from "react";
import type { Auth0ProviderOptions as RawAuth0ProviderOptions } from "@auth0/auth0-react";
import {
  Auth0Provider as RawAuth0Provider,
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from "@auth0/auth0-react";
import { IdentityProvider } from "../identity/identity-react";
import * as Auth0MimicCookie from "../mimic/auth0-mimic-cookie";
import { ErrorHandler } from "./error-handler";
import { getIdentityRulePayload } from "../identity/identity-storage";

export type Auth0ProviderOptions = RawAuth0ProviderOptions &
  Required<Pick<RawAuth0ProviderOptions, "redirectUri" | "audience">> & {
    withAuthenticationRequiredOptions?: WithAuthenticationRequiredOptions;
    /**
     * Opt-out for Auth0's withAuthenticationRequired HOC.
     *
     * NOTE: Setting this flag to `true` means your app is *not* protected by default.
     * Any pages that require authentication must be handled manually.
     */
    allowUnauthorizedAccess?: boolean;
  };

/**
 * ```jsx
 * <Auth0Provider
 *   domain={domain}
 *   clientId={clientId}
 *   redirectUri={window.location.origin}>
 *   <MyApp />
 * </Auth0Provider>
 * ```
 *
 * Provides the Auth0Context to its child components.
 */
export const Auth0Provider = ({
  children,
  clientId,
  withAuthenticationRequiredOptions,
  allowUnauthorizedAccess = false,
  ...opts
}: Auth0ProviderOptions) => {
  const ProtectedContent = withAuthenticationRequired(({ children }) => <>{children}</>, {
    returnTo: window.location.href.replace(window.location.origin, ""),
    onRedirecting: () => <div>Redirecting...</div>,
    ...withAuthenticationRequiredOptions,
  });

  return (
    <RawAuth0Provider {...getIdentityRulePayload()} {...Auth0MimicCookie.read()} clientId={clientId} {...opts}>
      <ErrorHandler clientId={clientId}>
        {allowUnauthorizedAccess ? (
          <IdentityProvider>{children}</IdentityProvider>
        ) : (
          <ProtectedContent>
            <IdentityProvider>{children}</IdentityProvider>
          </ProtectedContent>
        )}
      </ErrorHandler>
    </RawAuth0Provider>
  );
};
