import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useWindowFocus from "use-window-focus";

import { getIdentity } from "./identity";
import type { Identity } from "./identity";
import { write as writeActiveGrantCookie } from "../identity/identity-grant-cookie";
import { retrieveStoredUserId } from "./identity-storage";

const identityContext = React.createContext<Identity | undefined>(undefined);

export const IdentityProvider = ({ children }: { children: React.ReactNode }) => {
  const auth0 = useAuth0();
  const [identity, setIdentity] = React.useState<Identity | null>(null);
  const isUserReturningToTab = useWindowFocus();

  React.useEffect(() => {
    if (!auth0.isAuthenticated) {
      return;
    }

    (async () => {
      setIdentity(await getIdentity(auth0));
    })();
  }, [auth0]);

  React.useEffect(() => {
    if (isUserReturningToTab) writeActiveGrantCookie(retrieveStoredUserId());
  }, [isUserReturningToTab]);

  if (!auth0.isAuthenticated) {
    return <>{children}</>;
  }

  return identity ? <identityContext.Provider value={identity}>{children}</identityContext.Provider> : null;
};

export const useIdentity = () => {
  const identity = React.useContext(identityContext);

  if (!identity) {
    throw new Error("useIdentity must be used within the context of the Auth0Provider");
  }

  return identity;
};

export type WithIdentityProps = { identity: ReturnType<typeof useIdentity> };
export const withIdentity = <Props extends Record<string, unknown>>(WrappedComponent: React.ComponentType<Props>) => {
  const WithIdentity = (props: Props): React.ReactElement<Props & WithIdentityProps, any> => (
    <WrappedComponent {...props} identity={useIdentity()} />
  );

  WithIdentity.displayName = `WithIdentity(${WrappedComponent.displayName || WrappedComponent.name})`;

  return WithIdentity;
};
