import React, { useState } from 'react';

import {
	IdentityGrantCookie,
	retrieveStoredUserId,
} from '@equipmentshare/auth0-react';
import { useFlags } from '@equipmentshare/feature-flags';
import {
	EuiPopover,
	EuiIcon,
	EuiHeaderSectionItemButton,
} from '@equipmentshare/ds2';
import { WaffleIcon } from '@equipmentshare/ds2-icons';

import { useAppListQuery } from '../../api/api';
import { flagEnabledOrUndefined } from './access';
import {
	AppDrawerMenu,
	AppDrawerMenuProps,
} from './app-drawer-menu/app-drawer-menu';

type AppDrawerProps = AppDrawerMenuProps & {
	isLoading?: boolean;
};

const openApp = (url: string) => {
	IdentityGrantCookie.write(retrieveStoredUserId());
	window.open(url, '_blank', 'noopener');
};

export const AppDrawer = ({
	appList,
	isLoading = false,
	onAppClick,
}: AppDrawerProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const onButtonClick = () =>
		setIsDrawerOpen((prevIsDrawerOpen) => !prevIsDrawerOpen);
	const closeDrawer = () => setIsDrawerOpen(false);

	return (
		<EuiPopover
			initialFocus={false}
			button={
				<EuiHeaderSectionItemButton
					aria-expanded={isDrawerOpen}
					aria-haspopup="true"
					onClick={onButtonClick}
					isLoading={isLoading}
				>
					<EuiIcon type={WaffleIcon} size="l" />
				</EuiHeaderSectionItemButton>
			}
			isOpen={isDrawerOpen}
			anchorPosition="downRight"
			closePopover={closeDrawer}
			panelPaddingSize="s"
		>
			<AppDrawerMenu appList={appList} onAppClick={onAppClick} />
		</EuiPopover>
	);
};

export const AppDrawerConsumable = () => {
	const appListQuery = useAppListQuery();
	const isLoading = [appListQuery.isIdle, appListQuery.isLoading].some(Boolean);
	const flags = useFlags();
	const appList =
		appListQuery.isSuccess &&
		!!appListQuery.data &&
		flags &&
		appListQuery?.data?.filter((app) =>
			flagEnabledOrUndefined(app.unav_application_id, flags),
		);

	return (
		<AppDrawer
			appList={appList || []}
			isLoading={isLoading}
			onAppClick={openApp}
		/>
	);
};
