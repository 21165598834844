import React, { ReactNode, createContext, useContext } from 'react';
import { EnvironmentConfiguration } from './types';

const EnvironmentConfigurationContext = createContext<
	EnvironmentConfiguration | undefined
>(undefined);

export const useEnvironmentConfiguration = () => {
	const environmentConfiguration = useContext(EnvironmentConfigurationContext);

	if (environmentConfiguration === undefined) {
		throw new Error(
			'useEnvironmentConfiguration must be used within an <EnvironmentConfigurationProvider />',
		);
	}

	return environmentConfiguration;
};

export type EnvironmentConfigurationProps = {
	readonly children: ReactNode;
	readonly environmentConfiguration: EnvironmentConfiguration;
};

export const EnvironmentConfigurationProvider = ({
	children,
	environmentConfiguration,
}: EnvironmentConfigurationProps) => (
	<EnvironmentConfigurationContext.Provider value={environmentConfiguration}>
		{children}
	</EnvironmentConfigurationContext.Provider>
);
