import React from 'react';
import styled from 'styled-components';

const ANIMATION_WIDTH = 150;

type AnimatedSkeletonLoaderProps = {
	readonly $width?: number;
	readonly $height?: number;
};

export const SkeletonLoader = styled.div<AnimatedSkeletonLoaderProps>`
	width: ${(props) => props.$width}px;
	height: ${(props) => (props.$height ? `${props.$height}px` : '100%')};
	position: relative;
	overflow: hidden;

	@keyframes animate {
		from {
			left: -${(props) => props.$width}px;
		}
		to {
			left: 100%;
		}
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: -${(props) => props.$width}px;
		top: 0;
		height: 100%;
		width: ${(props) => props.$width}px;
		background: linear-gradient(
			to right,
			transparent 0%,
			rgba(196, 204, 211, 0.5) 50%,
			transparent 100%
		);
		animation: animate 1.75s cubic-bezier(0.4, 0, 0.2, 1) infinite;
	}
`;

export const AnimatedSkeletonLoader = ({
	$width,
	$height,
}: AnimatedSkeletonLoaderProps) => {
	return (
		<SkeletonLoader
			$width={$width ?? ANIMATION_WIDTH}
			$height={$height}
			data-testid="animated-skeleton-loader"
		/>
	);
};
