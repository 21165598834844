import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAppId } from '../../environment-configuration/app-id';
import { useAppListQuery } from '../../api/api';
import { AppIconSkeleton } from './skeleton/app-icon-skeleton';
import { AppNameSkeleton } from './skeleton/app-name-skeleton';
import {
	EuiHeaderSectionItem,
	EuiIcon,
	EuiTitle,
	TYPEWEIGHT,
	SIZE,
} from '@equipmentshare/ds2';

const AppIcon = styled(EuiIcon)`
	border-radius: ${SIZE.s};
	margin-right: ${SIZE.s};
`;

const AppTitleWeight = styled.span`
	font-weight: ${TYPEWEIGHT.semiBold};
`;

const AppTitleSection = styled(EuiHeaderSectionItem)`
	margin-left: ${SIZE.xs};
`;

export const AppTitle = () => {
	const appId = useAppId();
	const appListQuery = useAppListQuery();

	const appData = (appListQuery.data ?? []).find(
		({ unav_application_id }) => appId === unav_application_id,
	);

	const iconUrl = appData?.logo_url ?? '';
	const appNameFromQuery = appData?.display_name;

	const appName =
		appNameFromQuery ?? window.localStorage.getItem('es-unav-appName');

	useEffect(() => {
		if (appNameFromQuery) {
			window.localStorage.setItem('es-unav-appName', appNameFromQuery);
		}
	}, [appNameFromQuery]);

	return (
		<AppTitleSection aria-label={appName ? `${appName} logo` : 'logo'}>
			{iconUrl ? (
				<AppIcon type={iconUrl} size="xl" data-testid="app-icon" />
			) : (
				<AppIconSkeleton />
			)}
			<EuiTitle size="xs">
				{appName ? (
					<AppTitleWeight data-testid="app-name">{appName}</AppTitleWeight>
				) : (
					<AppNameSkeleton />
				)}
			</EuiTitle>
		</AppTitleSection>
	);
};

AppTitle.displayName = 'AppTitle';
