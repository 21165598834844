import type { IdentityFromAccessToken } from "./tokens/access-token";
import type { IdentityFromIdToken } from "./tokens/id-token";
import type { Identity } from "./identity";

export const removeEmptyValues = <TokenIdentity extends Partial<IdentityFromAccessToken | IdentityFromIdToken>>(
  identity: TokenIdentity
): TokenIdentity => {
  const identityCopy = { ...identity };

  Object.entries(identityCopy).forEach(([key, value]) => {
    if (typeof value === "undefined") {
      delete identityCopy[key as keyof TokenIdentity];
    }
  });

  return identityCopy;
};

export const castIdsToNumber = (identity: Partial<Identity>): Partial<Identity> => {
  const identityCopy = { ...identity };

  (["userId", "companyId", "securityLevelId"] as const).forEach((key) => {
    const value = Number(identityCopy[key]);
    const correctedValue = isNaN(value) ? undefined : value;

    if (correctedValue) {
      identityCopy[key] = correctedValue;
    }
  });

  return identityCopy;
};

export const safeParseInt = (input: string | null): number | null => {
  const maybeNumber = input ? Number.parseInt(input) : NaN;
  return isNaN(maybeNumber) ? null : maybeNumber;
};
