import { useQuery, UseQueryOptions } from 'react-query'

import {
	PaginatedResponse,
	EntityContact,
	EntityContactFilters,
} from '../models'
import { createQueryString } from '../utils/create_query_string'

const getEntityContacts = async(
	host: string,
	accessToken: string,
	filters?: EntityContactFilters,
): Promise<PaginatedResponse<EntityContact>> => {
	const url = `${host}/entity-contacts/${
		filters ? createQueryString( filters ) : ''
	}`

	const response = await fetch( url, {
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const contactsData = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( contactsData )
		throw new Error( `RequestLogicalError:getEntityContacts:${response.status}:${body}` )
	}

	return contactsData
}

export function useEntityContacts(
	host: string,
	accessToken: string,
	filters?: EntityContactFilters,
	options?: UseQueryOptions,
) {
	return useQuery(
		[ 'entityContacts', host, accessToken, filters, options ],
		async() => await getEntityContacts( host, accessToken, filters ),
		{
			enabled: !!( accessToken ) && ( options?.enabled !== false ),
		},
	)
}
