import React from 'react';
import styled from 'styled-components';
import {
	EuiLink,
	EuiFlexGroup,
	EuiFlexItem,
	COLORSLIGHT,
	TYPEWEIGHT,
	SIZE,
	EuiSideNavItemType,
} from '@equipmentshare/ds2';

const StyledLink = styled(EuiLink)`
	display: flex;
	margin: ${SIZE.xs};
	position: relative;
	flex: 1;
	border-radius: 6px;

	&.euiLink {
		position: relative;
		z-index: 1;
		color: ${COLORSLIGHT.darkShade};

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: -1;
			border-radius: 6px;
		}

		.euiListGroupItem {
			padding-left: 40px;
		}

		&.isSelectable.isSelected {
			color: ${COLORSLIGHT.darkestShade};
			font-weight: ${TYPEWEIGHT.semiBold};
			&:after {
				background: ${COLORSLIGHT.darkestShade};
				opacity: 0.08;
			}
			&:hover {
				cursor: default;
				text-decoration: none;
			}
		}

		&.isSelectable:not(.isSelected),
		&:not(.isSelectable).hasSubItems:not(.subItemSelected) {
			&:hover {
				color: ${COLORSLIGHT.darkestShade};
				&:after {
					background: ${COLORSLIGHT.darkestShade};
					opacity: 0.08;
				}
			}
		}

		&:not(.isSelectable) {
			&:hover,
			&:focus {
				text-decoration: none;
				cursor: default;
			}
		}

		&.subItemSelected {
			color: ${COLORSLIGHT.darkestShade};
			font-weight: ${TYPEWEIGHT.semiBold};
			&:not(:hover) svg {
				fill: ${COLORSLIGHT.darkestShade};
			}
			&:not(.navIsOpen):after {
				background: ${COLORSLIGHT.darkestShade};
				opacity: 0.08;
			}
		}
	}
`;

const StyledFlexGroup = styled(EuiFlexGroup)`
	padding: 9px 10px;
	font-size: ${SIZE.base};
	flex: 1;
	flex-wrap: nowrap;

	&.euiFlexGroup--responsive > .euiFlexItem {
		width: auto !important;
		flex-basis: initial !important;
		margin-bottom: 0 !important;
	}

	svg {
		width: 20px;
		height: 20px;
	}
`;

export type SideNavLinkProps = {
	item: EuiSideNavItemType<{}>;
	navIsOpen?: boolean;
};

export const SideNavLink = ({ item, navIsOpen }: SideNavLinkProps) => {
	const activeSubitem = item.items?.filter((itm) => itm.isSelected)?.length;

	const classNames = [];
	if (item.items?.length) classNames.push('hasSubItems');
	if (item.href || item.onClick) classNames.push('isSelectable');
	if (item.isSelected) classNames.push('isSelected');
	if (activeSubitem) classNames.push('subItemSelected');
	if (navIsOpen) classNames.push('navIsOpen');

	return (
		<StyledLink
			href={item.href}
			onClick={item.onClick}
			className={classNames.join(' ')}
			aria-label={item['aria-label']}
			{...(item.isSelected && { 'aria-current': 'page' })}
		>
			<StyledFlexGroup
				gutterSize="none"
				justifyContent="flexStart"
				alignItems="center"
			>
				<EuiFlexItem
					grow={false}
					style={{ paddingRight: navIsOpen ? SIZE.s : '0' }}
				>
					{item.icon}
				</EuiFlexItem>
				{navIsOpen ? <EuiFlexItem>{item.name}</EuiFlexItem> : null}
			</StyledFlexGroup>
		</StyledLink>
	);
};
