import React, { useState } from 'react';
import { AccountSwitcherUserGrants } from './account-switcher-user-grants/account-switcher-user-grants';
import ActiveUser from './account-switcher-user-grants/active-user-grant/active-user-grant';
import { useIdentityContext } from '../../identity/identity-provider';
import { useEnvironmentConfiguration } from '../../environment-configuration/context';
import { AccountDrawer } from '../account-drawer/account-drawer';
import { AccountTrigger } from '../account-trigger/account-trigger';
import { AccountLogout } from '../account-logout/account-logout';
import { ApiUserGrant } from '../../types/identity';
import {
	EuiPopover,
	EuiPopoverTitle,
	EuiPopoverFooter,
} from '@equipmentshare/ds2';

function showLegacyExperience(
	isLoading: boolean,
	activeUserGrant: ApiUserGrant | undefined,
	userGrants: ApiUserGrant[] | undefined,
) {
	return !!(!isLoading && !activeUserGrant && !userGrants);
}

export const AccountSwitcher = () => {
	const { auth0, clientId, returnTo } = useEnvironmentConfiguration();
	const [isAccountSwitcherOpen, setIsAccountSwitcherOpen] = useState(false);
	const closePopover = () => setIsAccountSwitcherOpen(false);
	const onAccountSwitcherClick = () =>
		setIsAccountSwitcherOpen(
			(prevIsAccountSwitcherOpen) => !prevIsAccountSwitcherOpen,
		);

	const { activeUserGrant, userGrants, isLoading } = useIdentityContext();

	if (showLegacyExperience(isLoading, activeUserGrant, userGrants)) {
		return <AccountDrawer />;
	}

	return (
		<EuiPopover
			button={
				<AccountTrigger
					userGrant={activeUserGrant}
					onClick={onAccountSwitcherClick}
				/>
			}
			isOpen={isAccountSwitcherOpen}
			closePopover={closePopover}
			panelPaddingSize="none"
			initialFocus={false}
		>
			{activeUserGrant ? (
				<EuiPopoverTitle paddingSize="m">
					<ActiveUser userGrant={activeUserGrant} />
				</EuiPopoverTitle>
			) : null}
			{userGrants ? (
				<AccountSwitcherUserGrants
					userGrants={userGrants}
					activeUserGrant={activeUserGrant}
				/>
			) : null}
			{clientId && returnTo ? (
				<EuiPopoverFooter>
					<AccountLogout
						logout={() => auth0.logout({ returnTo, client_id: clientId })}
					/>
				</EuiPopoverFooter>
			) : null}
		</EuiPopover>
	);
};
