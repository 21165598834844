import React from 'react';
import { ApiUserGrant } from '../../../../types/identity';
import { useIdentityContext } from '../../../../identity/identity-provider';

import { EuiListGroupItem } from '@equipmentshare/ds2';

export type InactiveUserGrantProps = {
	readonly userGrant: ApiUserGrant;
};

const InactiveUserGrant = ({ userGrant }: InactiveUserGrantProps) => {
	const { activateUserGrant } = useIdentityContext();

	return (
		<EuiListGroupItem
			label={userGrant.company_name}
			key={userGrant.company_id}
			size="s"
			role="menuitem"
			data-testid="inactive-user-grant"
			color="text"
			onClick={() => {
				activateUserGrant(userGrant);
			}}
		/>
	);
};

InactiveUserGrant.displayName = 'InactiveUserGrant';
export default InactiveUserGrant;
