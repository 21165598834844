import Cookies from "js-cookie";

const cookieName = "es-mimic-1umc";

const thirtySecondsFromNow = () => {
  const date = new Date();
  date.setTime(date.getTime() + 30 * 1000);
  return date;
};

export const createAttributes = (isLocalhost: boolean): Cookies.CookieAttributes => ({
  path: "/",
  expires: thirtySecondsFromNow(),
  secure: true,
  sameSite: "strict",
  domain: isLocalhost ? "localhost" : ".estrack.com",
});

export const read = (): string | null => {
  const cookieValue = Cookies.get(cookieName);
  return cookieValue ? cookieValue : null;
};

export const write = (userId: string | number, isLocalhost: boolean = false): void => {
  Cookies.set(cookieName, `${userId}`, createAttributes(isLocalhost));
};

export const remove = (): void => {
  Cookies.remove(cookieName, createAttributes(false));
  Cookies.remove(cookieName, createAttributes(true));
};
