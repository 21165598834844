const prefixes = {
  prod: "https://api.equipmentshare.com/",
  staging: "https://staging-api.equipmentshare.com/",
} as const;

/**
 * The default claims present in our access tokens. The keys of this object are
 * reserved keywords which is why they call these reserved claims.
 *
 * @see https://auth0.com/docs/security/tokens/json-web-tokens/json-web-token-claims#reserved-claims
 */
type ReservedClaims = {
  /** Raw token string (only available from the id token) */
  readonly __raw: string;
  /** Issuer */
  readonly iss?: string;
};

export type TokenClaims = ReservedClaims & Record<string, unknown>;

const wasIssuedFromProd = (claims: TokenClaims) => !claims.iss?.startsWith("https://staging-");

export const createCustomClaimGetter = <CustomClaimSuffixes>() => {
  return <Suffix extends keyof CustomClaimSuffixes>(
    claims: TokenClaims | undefined,
    keySuffix: Suffix
  ): CustomClaimSuffixes[Suffix] | undefined => {
    if (!claims) {
      return undefined;
    }

    const keyPrefix = wasIssuedFromProd(claims) ? prefixes.prod : prefixes.staging;
    const claimKey = `${keyPrefix}${keySuffix}`;

    return (claims[claimKey] as CustomClaimSuffixes[Suffix]) ?? undefined;
  };
};
