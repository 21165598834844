import type { Identity } from "./identity";

type RequiredFieldsOf<T> = {
  [P in keyof Required<T> as Pick<T, P> extends Required<Pick<T, P>> ? P : never]: T[P];
};

export const requiredFields: Array<keyof RequiredFieldsOf<Identity>> = [
  "accessToken",
  "idToken",
  "email",
  "fullName",
  "userId",
  "companyId",
  "securityLevelId",
];

export const findMissingIdentityFields = (identity: Partial<Identity>): string[] =>
  requiredFields.reduce((missingFields: string[], field: keyof Identity) => {
    if (identity[field] === undefined) missingFields.push(field);
    return missingFields;
  }, []);

export const isValidIdentity = (identity: Partial<Identity>): identity is Identity =>
  requiredFields.every((field) => identity[field] !== undefined);
