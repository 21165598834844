import {
	getIdentity,
	Auth0ContextInterface,
} from '@equipmentshare/auth0-react';

export const makeAuthHeader = async (auth0: Auth0ContextInterface) => {
	const { accessToken, idToken } = await getIdentity(auth0);

	return {
		authorization: `Bearer ${accessToken}`,
		'x-es-identity': idToken,
	} as const;
};
