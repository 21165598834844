import React from 'react';
import { EuiListGroup, EuiListGroupItem } from '@equipmentshare/ds2';
import { LogoutBoxLineIcon } from '@equipmentshare/ds2-icons';

export type AccountLogoutProps = {
	logout: () => void;
};

export const AccountLogout = ({ logout }: AccountLogoutProps) => (
	<EuiListGroup maxWidth={300}>
		<EuiListGroupItem
			label="Log out"
			key="app-logout"
			size="s"
			role="menuitem"
			aria-label="Log out of all accounts"
			data-testid="app-logout"
			onClick={logout}
			iconType={LogoutBoxLineIcon}
		/>
	</EuiListGroup>
);

AccountLogout.displayName = 'AccountLogout';
