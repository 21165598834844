import React from 'react';
import { ApiUserGrant } from '../../../../types/identity';

import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiText,
} from '@equipmentshare/ds2';

export type ActiveUserGrantProps = {
	readonly userGrant: ApiUserGrant;
};

const ActiveUser = ({ userGrant }: ActiveUserGrantProps) => (
	<EuiFlexGroup direction="column" data-testid="active-user-grant">
		<EuiFlexItem>
			<EuiTitle size="xs">
				<span>{userGrant?.company_name}</span>
			</EuiTitle>
			<EuiText size="s">{userGrant?.user_name}</EuiText>
			<EuiText size="xs" color="subdued">
				{userGrant?.user_email}
			</EuiText>
		</EuiFlexItem>
	</EuiFlexGroup>
);

ActiveUser.displayName = 'ActiveUser';
export default ActiveUser;
