import { extractIdentityFromAccessToken } from "./tokens/access-token";
import { extractIdentityFromIdToken } from "./tokens/id-token";
import { removeEmptyValues, castIdsToNumber } from "./identity-utils";

import type { Auth0ContextInterface } from "../auth0/context-modifier";
import { findMissingIdentityFields, isValidIdentity } from "./identity-validator";

export type Identity = Readonly<{
  accessToken: string;
  idToken: string;
  email: string;
  fullName: string;
  userId: number;
  companyId: number;
  securityLevelId: number;
  companyName?: string;
  readOnly?: boolean;
  identityId?: string;
}>;

/**
 * The shape of data our Auth0 Identity rule expects.
 * @see https://gitlab.internal.equipmentshare.com/equipmentshare/auth0-config/-/blob/master/rules/Identity.js
 */
export type IdentityRulePayload = Readonly<{
  identity: { es_user_id: string };
}>;

export const getIdentity = async (auth0: Auth0ContextInterface): Promise<Identity> => {
  const accessTokenIdentity = await extractIdentityFromAccessToken(auth0);
  const idTokenIdentity = await extractIdentityFromIdToken(auth0);

  const identity: Partial<Identity> = castIdsToNumber({
    ...removeEmptyValues(accessTokenIdentity),
    ...removeEmptyValues(idTokenIdentity),
  });

  if (!isValidIdentity(identity)) {
    throw new Error(`Malformed identity - missing fields: ${JSON.stringify(findMissingIdentityFields(identity))}`);
  }

  return identity;
};
