import React from 'react';
import {
	EuiToolTip,
	EuiIcon,
	EuiHeaderSectionItemButton,
} from '@equipmentshare/ds2';
import { QuestionLineIcon } from '@equipmentshare/ds2-icons';

const INTERCOM_HELP_PAGES = 'https://help.estrack.com/en/';
const ACCESSIBLE_TEXT = 'Help Page';

export const HelpPageIcon = () => {
	return (
		<EuiToolTip content={<p>{ACCESSIBLE_TEXT}</p>}>
			<EuiHeaderSectionItemButton
				aria-label={ACCESSIBLE_TEXT}
				href={INTERCOM_HELP_PAGES}
				target="_blank"
			>
				<EuiIcon type={QuestionLineIcon} size="l" />
			</EuiHeaderSectionItemButton>
		</EuiToolTip>
	);
};

HelpPageIcon.displayName = 'HelpPageIcon';
