import { EntityLocation } from 'src/models'

export function compareBusinessAddress(
	businessAddress1: Omit<EntityLocation, 'entity_location_id'> | undefined,
	businessAddress2: Omit<EntityLocation, 'entity_location_id'> | undefined,
): boolean {
	return (
		businessAddress1?.city === businessAddress2?.city &&
		businessAddress1?.entity_state_id === businessAddress2?.entity_state_id &&
		businessAddress1?.state === businessAddress2?.state &&
		businessAddress1?.street_1 === businessAddress2?.street_1 &&
		businessAddress1?.street_2 === businessAddress2?.street_2 &&
		businessAddress1?.zip_code === businessAddress2?.zip_code
	)
}
