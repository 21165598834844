import React from 'react';
import styled from 'styled-components';
import { AppDrawerApp, AppDrawerAppProps } from './app-drawer-app';
import { EuiKeyPadMenu, SIZE } from '@equipmentshare/ds2';
import { App } from 'src/types/app-list';

const GRID_COLUMN_WIDTH = '102px';
const AppMenu = styled(EuiKeyPadMenu)`
	display: grid;
	grid-template-columns: repeat(3, ${GRID_COLUMN_WIDTH});
	column-gap: 0;
	row-gap: ${SIZE.s};
	padding: ${SIZE.s};

	& > li {
		margin: 0;
	}
`;

export type AppDrawerMenuProps = {
	appList: App[];
	onAppClick: AppDrawerAppProps['onClick'];
};

export const AppDrawerMenu = ({ appList, onAppClick }: AppDrawerMenuProps) => {
	return (
		<AppMenu style={{ width: 322 }}>
			{appList.map((app) => (
				<AppDrawerApp app={app} key={app.name + app.url} onClick={onAppClick} />
			))}
		</AppMenu>
	);
};
