import * as React from 'react';
import { activateUserGrant } from './identity-state';
import { ApiIdentity, ApiUserGrant } from '../types/identity';
import { useIdentityUserGrantsQuery } from '../api/api';

export type IdentityContext = {
	identity: ApiIdentity | undefined;
	activateUserGrant: (userGrant: ApiUserGrant) => void;
	activeUserGrant: ApiUserGrant | undefined;
	userGrants: ApiUserGrant[] | undefined;
	isLoading: boolean;
};

export type IdentityContextProviderProps = {
	children: React.ReactNode;
};

const identityContext = React.createContext<IdentityContext | undefined>(
	undefined,
);

export const useIdentityContext = () => {
	const context = React.useContext(identityContext);

	if (!context) {
		throw new Error(
			'useIdentityContext must be used within the IdentityContextProvider',
		);
	}

	return context;
};

export const IdentityContextProvider = ({
	children,
}: IdentityContextProviderProps) => {
	const userGrantsQuery = useIdentityUserGrantsQuery();

	const isLoading = userGrantsQuery.isIdle || userGrantsQuery.isLoading;

	const identity = userGrantsQuery.data?.identity ?? undefined;
	const userGrants = userGrantsQuery.data?.userGrants ?? undefined;
	const activeUserGrant = userGrantsQuery.data?.activeUserGrant ?? undefined;

	return (
		<identityContext.Provider
			value={{
				identity,
				activateUserGrant: (userGrant: ApiUserGrant) => {
					activateUserGrant(userGrant);
				},
				userGrants,
				activeUserGrant,
				isLoading,
			}}
		>
			{children}
		</identityContext.Provider>
	);
};
