import React from 'react';
import styled from 'styled-components';
import { AnimatedSkeletonLoader } from '../../animated-skeleton-loader/animated-skeleton-loader';
import { COLORSLIGHT } from '@equipmentshare/ds2';

const Circle = styled.span`
	box-sizing: border-box;
	background-color: ${COLORSLIGHT.lightestShade};
	border-radius: 8px;
	align-items: center;
	display: inline-flex;
	justify-content: center;
	margin-right: 8px;
	flex-shrink: 0;
	overflow: hidden;
`;

export const AppIconSkeleton = () => (
	<Circle data-testid="app-icon-skeleton">
		<AnimatedSkeletonLoader $width={32} $height={32} />
	</Circle>
);

AppIconSkeleton.displayName = 'AppIconSkeleton';
