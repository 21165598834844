import React from 'react';
import InactiveUserGrant from './inactive-user-grant/inactive-user-grant';
import { ApiUserGrant } from '../../../types/identity';
import { EuiListGroup } from '@equipmentshare/ds2';

export type AccountSwitcherUserGrantsProps = {
	readonly userGrants: ApiUserGrant[];
	readonly activeUserGrant?: ApiUserGrant;
};

export const AccountSwitcherUserGrants = ({
	userGrants,
	activeUserGrant,
}: AccountSwitcherUserGrantsProps) => {
	const inactiveUserGrants = activeUserGrant
		? userGrants.filter((id) => id.user_id !== activeUserGrant.user_id)
		: userGrants;
	return (
		<EuiListGroup maxWidth={300} data-testid="user-grants-list" color="text">
			{Object.values(inactiveUserGrants).map((userGrant) => (
				<InactiveUserGrant
					userGrant={userGrant}
					key={`${userGrant?.identity_id}-${userGrant?.user_id}`}
				/>
			))}
		</EuiListGroup>
	);
};
