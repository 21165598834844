import React, { ReactNode, createContext, useContext } from 'react';
import { ReactQueryOptions } from './types';

const ReactQueryOptionsContext = createContext<
	ReactQueryOptions | undefined | null
>(null);

export const useReactQueryOptions = () => {
	const reactQueryOptions = useContext(ReactQueryOptionsContext);

	if (reactQueryOptions === null) {
		throw new Error(
			'useReactQueryOptions must be used within an <ReactQueryOptionsProvider />',
		);
	}

	return reactQueryOptions;
};

export type ReactQueryOptionsProviderProps = {
	readonly children: ReactNode;
	readonly reactQueryOptions?: ReactQueryOptions;
};

export const ReactQueryOptionsProvider = ({
	children,
	reactQueryOptions,
}: ReactQueryOptionsProviderProps) => {
	return (
		<ReactQueryOptionsContext.Provider value={reactQueryOptions}>
			{children}
		</ReactQueryOptionsContext.Provider>
	);
};
