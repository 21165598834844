import { useQuery } from 'react-query'
import { EntityLookupValues } from '../models'

const getEntityLookupValues = async( host: string, accessToken: string | undefined ): Promise<EntityLookupValues> => {
	const url = `${host}/entity-lookup-values/`

	const response = await fetch( url, {
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const submittersData = await ( response.json() )

	if ( !response.ok ) {
		const body = JSON.stringify( submittersData )
		throw new Error( `RequestLogicalError:getEntityLookupValues:${response.status}:${body}` )
	}

	return submittersData
}

export function useEntityLookupValues( host: string, accessToken: string | undefined ) {
	return useQuery(
		[ 'entityLookupValues', host, accessToken ],
		async() => await getEntityLookupValues( host, accessToken ),
		{
			enabled: !!( accessToken ),
		},
	)
}
