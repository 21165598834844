import { QueryKeyFormatter } from './types';
import { useReactQueryOptions } from './context';

const defaultQueryKeyFormatter: QueryKeyFormatter = (internalQueryKey) =>
	internalQueryKey;

export const useQueryKeyFormatter = () => {
	const reactQueryOptions = useReactQueryOptions();

	return reactQueryOptions?.queryKeyFormatter ?? defaultQueryKeyFormatter;
};
