/* eslint-disable no-console */
import { useQuery, UseQueryResult } from 'react-query';
import axios from 'axios';
import { minutesToMilliseconds } from './utils/minutes-to-milliseconds/minutes-to-milliseconds';
import { makeAuthHeader } from './utils/auth-token-to-authorization-header/auth-token-to-authorization-header';
import { useApiRoot } from '../environment-configuration/api-root';
import { User } from '../types/user';
import { App } from '../types/app-list';
import { useQueryKeyFormatter } from '../react-query/query-key-formatter';
import { useEnvironmentConfiguration } from '../environment-configuration/context';
import {
	ApiIdentity,
	ApiIdentityResponse,
	ApiUserGrant,
} from '../types/identity';
import { retrieveStoredUserId, storeUserId } from '@equipmentshare/auth0-react';

export const useUserQuery = (): UseQueryResult<User> => {
	const { auth0 } = useEnvironmentConfiguration();
	const apiRoot = useApiRoot();
	const queryKeyFormatter = useQueryKeyFormatter();

	const queryKey = queryKeyFormatter(['universal-header', 'user']);

	const userQuery = useQuery<User>(
		queryKey,
		async () => {
			const headers = await makeAuthHeader(auth0);

			return axios.get(`${apiRoot}/user`, { headers }).then((res) => res.data);
		},
		{
			staleTime: minutesToMilliseconds(5),
			onError: (error) =>
				console.error(
					`Encountered the following error while fetching the user: ${error}`,
				),
		},
	);

	return userQuery;
};

export const useAppListQuery = (): UseQueryResult<App[]> => {
	const userQuery = useUserQuery();
	const { auth0 } = useEnvironmentConfiguration();
	const apiRoot = useApiRoot();
	const queryKeyFormatter = useQueryKeyFormatter();

	const userId = userQuery.data?.user_id;

	const queryKey = queryKeyFormatter([
		'universal-header',
		'user',
		userId,
		'apps',
	]);

	const appListQuery = useQuery<App[]>(
		queryKey,
		async () => {
			const headers = await makeAuthHeader(auth0);

			return axios
				.get(`${apiRoot}/users/${userId}/urls`, { headers })
				.then((res) => res.data);
		},
		{
			enabled: !!userId,
			refetchOnMount: false,
			staleTime: minutesToMilliseconds(5),
			onError: (error) =>
				console.error(
					`Encountered the following error while fetching the user app list: ${error}`,
				),
		},
	);

	return appListQuery;
};

const getActiveUserGrant = (
	defaultUserGrant: ApiUserGrant,
	userGrants: ApiUserGrant[],
) => {
	const storedUserId = retrieveStoredUserId();

	if (!storedUserId) {
		storeUserId(defaultUserGrant.user_id);
		return defaultUserGrant;
	}

	const matchedUserGrant = userGrants.find(
		// eslint-disable-next-line eqeqeq
		(freshUserGrant) => freshUserGrant.user_id == storedUserId,
	);

	return matchedUserGrant ?? defaultUserGrant;
};

export type IdentityUserGrants = {
	identity: ApiIdentity;
	activeUserGrant: ApiUserGrant;
	userGrants: ApiUserGrant[];
};

export const useIdentityUserGrantsQuery = (): UseQueryResult<
	IdentityUserGrants | undefined
> => {
	const apiRoot = useApiRoot();
	const { auth0 } = useEnvironmentConfiguration();
	const queryKeyFormatter = useQueryKeyFormatter();

	const queryKey = queryKeyFormatter(['universal-header', 'identity']);

	return useQuery<IdentityUserGrants | undefined>(
		queryKey,
		async () => {
			const headers = await makeAuthHeader(auth0);
			const identityRes = await axios
				.get<ApiIdentityResponse>(`${apiRoot}/identity`, { headers })
				.then((res) => res.data)
				.catch((reason) => {
					if (reason?.request?.status !== 404) {
						console.error(reason);
					}
					return undefined;
				});

			if (identityRes === undefined) {
				return undefined;
			}

			const { default_user_grant, user_grants, ...identity } = identityRes;
			const activeUserGrant = getActiveUserGrant(
				default_user_grant,
				user_grants,
			);

			return {
				identity,
				activeUserGrant,
				userGrants: user_grants,
			};
		},
		{ staleTime: Infinity },
	);
};
