import React from 'react';
import styled from 'styled-components';
import {
	EuiSpacer,
	EuiFlexItem,
	EuiFlexGroup,
	getBreakpoint,
	EuiSideNavItemType,
} from '@equipmentshare/ds2';
import { SideNavItem } from '../side-nav-item/side-nav-item';

const SideNavGroup = styled(EuiFlexGroup)`
	flex-wrap: nowrap;
	margin-bottom: 6px;
	overflow: hidden auto;
	&.mq-xs,
	&.mq-s {
		flex-grow: 0;
	}
`;

const SideNavItemWrapper = styled(EuiFlexItem)`
	.euiPopover__anchor {
		display: flex;
	}
`;

export type SideNavItemsProps = {
	items: EuiSideNavItemType<{}>[];
	isOpen?: boolean;
};

export const SideNavItems = ({ items, isOpen }: SideNavItemsProps) => {
	const breakpoint: string = getBreakpoint(window.innerWidth) ?? '';

	return (
		<SideNavGroup
			direction="column"
			justifyContent="flexStart"
			alignItems="stretch"
			gutterSize="none"
			wrap={false}
			className={`mq-${breakpoint}`}
		>
			<EuiSpacer size="s" />
			{items?.map((item) => (
				<SideNavItemWrapper
					grow={item.className?.includes('flexGrow') ?? false}
					key={`${item.id}${isOpen ? '-expanded' : '-collapsed'}`}
					className={item.className}
					data-testid="sidenav-item"
				>
					<SideNavItem item={item} navIsOpen={isOpen} />
				</SideNavItemWrapper>
			))}
		</SideNavGroup>
	);
};
