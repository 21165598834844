import React from 'react';
import { EnvironmentConfiguration } from '../../environment-configuration/types';
import { ReactQueryOptions } from '../../react-query/types';

import { SideNav } from '../side-nav/side-nav';
import { UniversalHeader } from '../universal-header/universal-header';
import { AppTitle } from '../app-title/app-title';
import { HelpPageIcon } from '../help-page-icon/help-page-icon';
import { AppDrawerConsumable } from '../app-drawer/app-drawer';
import { AccountSwitcher } from '../account-switcher/account-switcher';

import {
	EuiProvider,
	THEME_TOKENS,
	EuiHeaderSection,
	EuiHeaderSectionItem,
	EuiSideNavItemType,
	buildTheme,
} from '@equipmentshare/ds2';

export type UniversalHeaderConsumableProps = {
	readonly environmentConfiguration: EnvironmentConfiguration;
	readonly reactQueryOptions?: ReactQueryOptions;
	readonly sideNavItems?: EuiSideNavItemType<{}>[];
	readonly sideNavOpen?: boolean;
};

export const UniversalHeaderConsumable = ({
	environmentConfiguration,
	reactQueryOptions,
	sideNavItems,
	sideNavOpen,
}: UniversalHeaderConsumableProps) => {
	const theme = buildTheme(THEME_TOKENS, 'DS2THEME');
	return (
		<EuiProvider theme={theme}>
			<UniversalHeader
				environmentConfiguration={environmentConfiguration}
				reactQueryOptions={reactQueryOptions}
			>
				<EuiHeaderSection grow={false}>
					{sideNavItems ? (
						<EuiHeaderSectionItem data-testid="header-sidenav">
							<SideNav items={sideNavItems} isOpen={sideNavOpen} />
						</EuiHeaderSectionItem>
					) : null}
					<EuiHeaderSectionItem>
						<AppTitle />
					</EuiHeaderSectionItem>
				</EuiHeaderSection>

				<EuiHeaderSection side="right">
					<EuiHeaderSectionItem>
						<HelpPageIcon />
					</EuiHeaderSectionItem>
					<EuiHeaderSectionItem>
						<AppDrawerConsumable />
					</EuiHeaderSectionItem>
					<EuiHeaderSectionItem>
						<AccountSwitcher />
					</EuiHeaderSectionItem>
				</EuiHeaderSection>
			</UniversalHeader>
		</EuiProvider>
	);
};

UniversalHeaderConsumable.displayName = 'UniversalHeader';
