import { useQuery } from 'react-query'

import { Entity } from '../models'

const getEntity = async(
	host: string,
	accessToken: string,
	entityId: string,
): Promise<Entity> => {
	const url = `${host}/entities/${entityId}`

	const response = await fetch( url, {
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const entityData = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entityData )
		throw new Error( `RequestLogicalError:getEntity:${response.status}:${body}` )
	}

	return entityData
}

export function useEntity( host: string, accessToken: string, entityId: string ) {
	return useQuery(
		[ `getEntity/entityId:${entityId}`, host, accessToken ],
		async() => await getEntity( host, accessToken, entityId ),
		{
			enabled: !!( accessToken ) && !!( entityId ),
		},
	)
}
