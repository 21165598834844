import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import {
	EuiHeaderSectionItemButton,
	EuiCollapsibleNav,
	COLORSLIGHT,
	EuiSideNavItemType,
	isWithinMaxBreakpoint,
} from '@equipmentshare/ds2';
import { SideNavItems } from '../side-nav-items/side-nav-items';
import { SideNavTrigger } from '../side-nav-trigger/side-nav-trigger';

const CollapsibleSideNav = styled(EuiCollapsibleNav)`
	&.euiFlyout.euiFlyout--left {
		background: ${COLORSLIGHT.lightestShade};
		border-right-width: 1px;
	}

	.euiFlyout__closeButton {
		display: none;
	}
`;

export type SideNavProps = {
	items: EuiSideNavItemType<{}>[];
	initialOpen?: boolean;
	isOpen?: boolean;
	onChange?: (isOpen: boolean) => void;
};

export const SideNav = ({
	items,
	isOpen,
	initialOpen,
	onChange,
}: SideNavProps) => {
	const [openState, setOpenState] = useState(() => {
		const isSmallScreen = isWithinMaxBreakpoint(window.innerWidth, 's');
		return !(initialOpen ?? isSmallScreen);
	});

	const navIsOpen = isOpen ?? openState;
	const setOpen = useCallback(
		(state: boolean) => {
			if (onChange) {
				onChange(state);
			} else {
				setOpenState(state);
			}
		},
		[onChange, setOpenState],
	);

	return (
		<CollapsibleSideNav
			id="collapsibleAppNav"
			aria-label="Main navigation"
			isOpen={navIsOpen}
			isDocked
			hideCloseButton
			showButtonIfDocked
			ownFocus={false}
			paddingSize="l"
			size={navIsOpen ? '212px' : '50px'}
			button={
				<EuiHeaderSectionItemButton
					aria-label="Toggle main navigation"
					onClick={() => setOpen(!navIsOpen)}
				>
					<SideNavTrigger open={navIsOpen} />
				</EuiHeaderSectionItemButton>
			}
			onClose={() => setOpen(false)}
		>
			<SideNavItems items={items} isOpen={navIsOpen} />
		</CollapsibleSideNav>
	);
};

SideNav.displayName = 'SideNav';
