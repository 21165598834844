import React from 'react';
import styled from 'styled-components';
import { AnimatedSkeletonLoader } from '../../animated-skeleton-loader/animated-skeleton-loader';

const TitleSkeleton = styled('div')`
	overflow: hidden;
	position: relative;
	width: 150px;
	height: 16px;
`;

export const AppNameSkeleton = () => {
	return (
		<TitleSkeleton data-testid="app-name-skeleton">
			<AnimatedSkeletonLoader $width={150} $height={16} />
		</TitleSkeleton>
	);
};
