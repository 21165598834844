import Cookies from "js-cookie";

export const cookieName = "es-mimic";

const twelveHoursFromNow = () => {
  const date = new Date();
  date.setTime(date.getTime() + 12 * 60 * 60 * 1000);
  return date;
};

export const createAttributes = (isLocalhost: boolean): Cookies.CookieAttributes => ({
  path: "/",
  expires: twelveHoursFromNow(),
  secure: true,
  sameSite: "None",
  domain: isLocalhost ? "localhost" : ".estrack.com",
});

/**
 * The shape of data our Auth0 Mimic User rule expects.
 * @see https://gitlab.internal.equipmentshare.com/equipmentshare/auth0-config/-/blob/master/rules/Mimic%20User.js
 */
export type MimicRulePayload = Readonly<{ mimic: string }>;

export const read = (): MimicRulePayload | {} => {
  const cookieValue = Cookies.get(cookieName);
  return cookieValue ? { mimic: cookieValue } : {};
};

export const write = (json: string, isLocalhost: boolean = false): void => {
  Cookies.set(cookieName, json, createAttributes(isLocalhost));
};

export const remove = (): void => {
  Cookies.remove(cookieName, createAttributes(true));
  Cookies.remove(cookieName, createAttributes(false));
};
