import React from 'react';
import styled from 'styled-components';
import {
	EuiListGroup,
	EuiListGroupItem,
	COLORSLIGHT,
	TYPEWEIGHT,
	SIZE,
	EuiSideNavItemType,
} from '@equipmentshare/ds2';

const StyledListGroupItem = styled(EuiListGroupItem)`
	position: relative;
	z-index: 1;
	font-size: 14px;

	button,
	a {
		color: ${COLORSLIGHT.text};
	}

	&.euiListGroupItem:not(:first-of-type) {
		margin-top: 2px;
	}

	&.euiListGroupItem--isSelected,
	&.euiListGroupItem:hover {
		background: none;

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: -1;
			border-radius: 6px;
			background: ${COLORSLIGHT.darkestShade};
			opacity: 0.1;
		}
	}

	&.euiListGroupItem--isSelected {
		color: ${COLORSLIGHT.darkestShade};
		font-weight: ${TYPEWEIGHT.semiBold};

		&:hover {
			background: none;

			button.euiListGroupItem__button {
				text-decoration: none;
			}
		}

		button:hover {
			cursor: default;
		}
	}

	&.navIsOpen {
		margin: 0 4px;
		padding-right: ${SIZE.m};
		padding-left: 30px;
	}
`;

export type SideNavSubitemsProps = {
	items: EuiSideNavItemType<{}>[];
	navIsOpen?: boolean;
};

export const SideNavSubitems = ({ items, navIsOpen }: SideNavSubitemsProps) => (
	<EuiListGroup flush size="s">
		{items.map((item) => (
			<StyledListGroupItem
				onClick={item.onClick}
				href={item.href}
				label={item.name}
				key={item.id}
				className={`${item.isSelected ? 'euiListGroupItem--isSelected' : ''} ${
					navIsOpen ? 'navIsOpen' : ''
				}`}
				{...(item.isSelected && { 'aria-current': 'page' })}
			/>
		))}
	</EuiListGroup>
);
