import { useQuery } from 'react-query'

import { Entity, PaginatedResponse } from '../models'

export type EntitiesVariables = Partial<{
	include_inactive: boolean,
	limit: number,
	offset: number,
	search: string,
	name: string,
	sort_col: keyof Entity,
	sort_dir: 'asc' | 'desc',
	is_vendor: boolean,
	is_customer: boolean,
}>

const defaultEntitiesVariables: EntitiesVariables = {
	include_inactive: false,
	sort_col: 'entity_id',
	sort_dir: 'desc',
}

export const getEntities = async(
	host: string,
	accessToken: string,
	variables: EntitiesVariables = {},
): Promise<PaginatedResponse<Entity>> => {
	const variablesWithDefaults = { ...defaultEntitiesVariables, ...variables }
	const urlParams = Object.entries( variablesWithDefaults ).map( ( [ key, value ] ) => `${key}=${value}` )
	const url = `${host}/entities/?${urlParams.join( '&' )}`

	const response = await fetch( url, {
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const entitiesData = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entitiesData )
		throw new Error( `RequestLogicalError:getEntities:${response.status}:${body}` )
	}

	return entitiesData
}

export function useEntities( host: string, accessToken: string, variables?: EntitiesVariables ) {
	return useQuery(
		[ 'getEntities', host, accessToken, variables ],
		async() => await getEntities( host, accessToken, variables ),
		{
			enabled: !!accessToken,
		},
	)
}
