import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { EnvironmentConfigurationProvider } from '../../environment-configuration/context';
import { EnvironmentConfiguration } from '../../environment-configuration/types';
import { ReactQueryOptions } from '../../react-query/types';
import { ReactQueryOptionsProvider } from '../../react-query/context';
import { IdentityContextProvider } from '../../identity/identity-provider';

import { EuiHeader, SIZE } from '@equipmentshare/ds2';

export type UniversalHeaderProps = {
	readonly children: ReactNode;
	readonly environmentConfiguration: EnvironmentConfiguration;
	readonly reactQueryOptions?: ReactQueryOptions;
};

const StyledEuiHeader = styled(EuiHeader)`
	height: 56px;
	padding-left: ${SIZE.xs};
	padding-right: ${SIZE.s};
`;

export const UniversalHeader = ({
	children,
	environmentConfiguration,
	reactQueryOptions,
}: UniversalHeaderProps) => {
	return (
		<ReactQueryOptionsProvider reactQueryOptions={reactQueryOptions}>
			<EnvironmentConfigurationProvider
				environmentConfiguration={environmentConfiguration}
			>
				<IdentityContextProvider>
					<StyledEuiHeader position="fixed" role="banner">
						{children}
					</StyledEuiHeader>
				</IdentityContextProvider>
			</EnvironmentConfigurationProvider>
		</ReactQueryOptionsProvider>
	);
};

UniversalHeader.displayName = 'UniversalHeader';
