import { IdentityRulePayload } from "./identity";
import { safeParseInt } from "./identity-utils";
import * as IdentityMimicCookie from "../mimic/identity-mimic-cookie";
import * as GrantCookie from "./identity-grant-cookie";

export const STORAGE_KEY = "es-active-id";

export const retrieveStoredUserId = (): number | null => {
  const activeIdFromSession = safeParseInt(sessionStorage.getItem(STORAGE_KEY));
  const activeIdFromMimicCookie = safeParseInt(IdentityMimicCookie.read());
  const activeIdFromGrantCookie = safeParseInt(GrantCookie.read());

  if (activeIdFromSession) {
    return activeIdFromSession;
  } else if (activeIdFromMimicCookie) {
    storeUserId(activeIdFromMimicCookie);
    GrantCookie.write(activeIdFromMimicCookie);
    IdentityMimicCookie.remove();
    return activeIdFromMimicCookie;
  } else if (activeIdFromGrantCookie) {
    storeUserId(activeIdFromGrantCookie);
    return activeIdFromGrantCookie;
  } else {
    return null;
  }
};

export const storeUserId = (userId: string | number): void => {
  sessionStorage.setItem(STORAGE_KEY, `${userId}`);
};

export const deleteStoredUserId = (): void => {
  sessionStorage.removeItem(STORAGE_KEY);
};

export const getIdentityRulePayload = (): IdentityRulePayload | {} => {
  const userId = retrieveStoredUserId();

  return userId ? { identity: JSON.stringify({ es_user_id: userId }) } : {};
};
