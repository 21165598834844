import { useMutation } from 'react-query'
import { EntityLocation } from '../models'

export type EntityLocationData = Pick<
	EntityLocation,
	'street_1' | 'street_2' | 'city' | 'entity_state_id' | 'zip_code'
>;

export const createEntityLocation = async(
	host: string,
	accessToken: string,
	entityLocationData: EntityLocationData,
): Promise<EntityLocation> => {
	const response = await fetch( `${host}/entity-locations/`, {
		method: 'POST',
		body: JSON.stringify( {
			street_1: entityLocationData.street_1,
			street_2: entityLocationData.street_2,
			city: entityLocationData.city,
			entity_state_id: entityLocationData.entity_state_id,
			zip_code: entityLocationData.zip_code,
		} ),
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )
	const entityLocation = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entityLocation )
		throw new Error( `RequestLogicalError:createEntityLocation:${response.status}:${body}` )
	}

	return entityLocation
}

export const updateEntityLocation = async(
	host: string,
	accessToken: string,
	entityLocation: EntityLocation,
): Promise<EntityLocation> => {
	const response = await fetch(
		`${host}/entity-locations/${entityLocation.entity_location_id}`,
		{
			method: 'PATCH',
			body: JSON.stringify( {
				street_1: entityLocation.street_1,
				street_2: entityLocation.street_2,
				city: entityLocation.city,
				entity_state_id: entityLocation.entity_state_id,
				zip_code: entityLocation.zip_code,
			} ),
			headers: new Headers( {
				'Authorization': `Bearer ${accessToken}`,
				'content-type': 'application/json',
			} ),
		},
	)
	const updatedEntityLocation = await response.json()

	if ( !response.ok ) {
		const body = JSON.stringify( entityLocation )
		throw new Error( `RequestLogicalError:updateEntityLocation:${response.status}:${body}` )
	}

	return updatedEntityLocation
}

export function createEntityLocationMutation(
	host: string,
	accessToken: string,
) {
	return useMutation(
		[ 'createEntityLocation', host, accessToken ],
		async( entityContactData: EntityLocationData ) => await createEntityLocation( host, accessToken, entityContactData ),
	)
}
