export type State = {
	abbreviation?: string,
	name?: string,
	entity_state_id?: number,
};

export type EntityTaxClassification = {
	entity_tax_classification_id: number,
	name: string,
};

export type EntityLocation = {
	entity_location_id: number,
	street_1: string,
	state?: State,
	street_2: string,
	zip_code: string,
	city: string,
	entity_state_id: number | null,
};

export type EntityVendorSettings = {
	entity_vendor_settings_id: number,
	entity_tax_classification: EntityTaxClassification,
	notes: string,
	credit_limit?: number | null,
	w9_on_file: string,
	entity_net_terms_id?: number | null,
	entity_net_term?: EntityNetTerms,
	entity_id: number,
	external_erp_vendor_ref: string,
	ap_address: EntityLocation,
	entity_tax_classification_id: number,
	ap_address_id: number,
};

export type EntityType = {
	name: string,
	entity_type_id: number,
};

export enum EntityTypeIds {
	INDIVIDUAL = 1,
	BUSINESS = 2,
}

export type Entity = {
	is_customer: boolean,
	entity_vendor_settings: EntityVendorSettings | null,
	entity_type_id: EntityTypeIds,
	ein: number | null,
	active: boolean,
	entity_type: EntityType,
	is_vendor: boolean,
	name: string,
	entity_id: number,
	primary_contact: EntityContact | null,
	business_address?: EntityLocation,
	business_address_id: number | null,
};

export type EntityContact = {
	entity_contact_id: number,
	company_id: number,
	first_name: string,
	last_name: string,
	email_address?: string | null,
	phone_number: string,
	entities: EntityContactXref[],
};

export type PaginatedResponse<Model> = {
	data: Model[],
	total: number,
};

export type EntityContactFilters = Partial<{
	entity_id: number | string,
	search: string,
	email_address: string,
	limit: number,
	offset: number,
	sort_col: keyof EntityContact,
	sort_dir: 'asc' | 'desc',
}>;
export type EntityNetTerms = {
	entity_net_terms_id: number,
	name: string,
	days: number,
};

export type EntityState = {
	entity_state_id: number,
	name: string,
	abbreviation: string,
};

export enum EntityContactTypeIds {
	AP_CONTACT = 1,
	PRIMARY_CONTACT = 2,
}

export type EntityContactType = {
	entity_contact_type_id: EntityContactTypeIds,
	name: string,
}

export type EntityContactXref = {
	entity_contact_xref_id: number,
	entity_id: number,
	entity_contact_type?: EntityContactType,
	entity_contact_type_id: EntityContactTypeIds,
	entity_contact_id: number,
	entity_contact?: EntityContact,
	entity: Pick<Entity, 'entity_id' | 'name'>,
}

export type EntityLookupValues = {
	entity_net_terms: EntityNetTerms[],
	entity_types: EntityType[],
	entity_tax_classifications: EntityTaxClassification[],
	entity_states: EntityState[],
	entity_contact_types: EntityContactType[],
}
