import React from 'react';
import { EuiIcon, EuiIconProps, EuiSideNavItemType } from '@equipmentshare/ds2';

export function createNavItem<T = {}>(
	id: string,
	name: string,
	icon?: EuiIconProps['type'],
	action?: string | (() => void),
) {
	const item: EuiSideNavItemType<T> = {
		id,
		name,
		'aria-label': name,
	};

	if (icon) {
		item.icon = <EuiIcon type={icon} />;
	}
	if (action) {
		if (typeof action === 'string') {
			item.href = action;
		} else if (typeof action === 'function') {
			item.onClick = action;
		}
	}

	return item;
}
