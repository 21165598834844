import React from 'react';

/**
 * creates an onClick handler that calls preventDefault
 * and returns an object with href and onClick properties
 */
export function createItemClickHandler(
	href: string,
	navigate: (to: string) => any,
) {
	return {
		href,
		onClick: (e: React.MouseEvent) => {
			e.preventDefault();
			navigate(href);
		},
	};
}
