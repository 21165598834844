import JWTDecoder from "jwt-decode";

import { createCustomClaimGetter, TokenClaims } from "./token-claims";

import type { Identity } from "../identity";
import type { Auth0ContextInterface } from "../../auth0/context-modifier";

/**
 * @see https://auth0.com/docs/security/tokens/json-web-tokens/json-web-token-claims#custom-claims
 */
type CustomProps = {
  readonly es_user_id: number;
  readonly es_company_id: number;
  readonly es_security_level_id: number;
  readonly es_user_name: string;
  readonly es_user_email: string;
};

export type IdentityFromAccessToken = Pick<
  Identity,
  "userId" | "companyId" | "securityLevelId" | "accessToken" | "fullName" | "email"
>;

const getCustomClaim = createCustomClaimGetter<CustomProps>();

const isNonEmptyObject = (result: any) => typeof result === "object" && Object.keys(result).length > 0;

const decodeAccessToken = (encodedToken: string): TokenClaims => {
  try {
    const result = JWTDecoder<TokenClaims>(encodedToken);

    if (isNonEmptyObject(result)) {
      return result;
    } else {
      throw Error("Decoded token contains an invalid payload");
    }
  } catch (error) {
    throw error;
  }
};

export const extractIdentityFromAccessToken = async (
  auth0: Auth0ContextInterface
): Promise<Partial<IdentityFromAccessToken>> => {
  const token = await auth0.getAccessTokenSilently();
  const claims = decodeAccessToken(token);

  return {
    userId: getCustomClaim(claims, "es_user_id"),
    companyId: getCustomClaim(claims, "es_company_id"),
    securityLevelId: getCustomClaim(claims, "es_security_level_id"),
    fullName: getCustomClaim(claims, "es_user_name"),
    email: getCustomClaim(claims, "es_user_email"),
    accessToken: token,
  };
};
