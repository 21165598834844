import * as React from "react";
import type {
  Auth0ContextInterface as RawAuth0ContextInterface,
  LogoutOptions as RawLogoutOptions,
} from "@auth0/auth0-react";
import * as GrantCookie from "../identity/identity-grant-cookie";
import { User as Auth0User } from "@auth0/auth0-react";
import { deleteStoredUserId } from "../identity/identity-storage";
import { removeMimicryCookies } from "../mimic/mimic-utils";

type LogoutOptions = RawLogoutOptions & Required<Pick<RawLogoutOptions, "returnTo" | "client_id">>;

const useModifiedLogout = (logout: Auth0ContextInterface["logout"]) =>
  React.useCallback(
    (opts: LogoutOptions): void => {
      removeMimicryCookies();
      deleteStoredUserId();
      GrantCookie.remove();
      logout(opts);
    },
    [logout]
  );

export type Auth0ContextInterface<User extends Auth0User = Auth0User> = Omit<
  RawAuth0ContextInterface<User>,
  "logout"
> & {
  logout: (opts: LogoutOptions) => void;
};

export const modifyAuth0Context = <User extends Auth0User = Auth0User>(
  auth0Context: Auth0ContextInterface<User>
): Auth0ContextInterface<User> => ({
  ...auth0Context,
  logout: useModifiedLogout(auth0Context.logout),
});
