import { useMutation } from 'react-query'
import { EntityContactXref } from '../models'

export type EntityContactXrefData = Pick<
	EntityContactXref,
	'entity_id' | 'entity_contact_id' | 'entity_contact_type_id'
>;
export type DeleteEntityContactXrefData = Pick<
	EntityContactXref,
	'entity_id' | 'entity_contact_id'
>;

export const createEntityContactXref = async(
	host: string,
	accessToken: string,
	entityContactXrefData: EntityContactXrefData,
): Promise<void> => {
	const response = await fetch( `${host}/entity-contact-xrefs/`, {
		method: 'POST',
		body: JSON.stringify( {
			entity_id: entityContactXrefData.entity_id,
			entity_contact_id: entityContactXrefData.entity_contact_id,
			entity_contact_type_id: entityContactXrefData.entity_contact_type_id,
		} ),
		headers: new Headers( {
			'Authorization': `Bearer ${accessToken}`,
			'content-type': 'application/json',
		} ),
	} )

	if ( !response.ok ) {
		throw new Error( `RequestLogicalError:createEntityContactXref:${response.status}` )
	}
}

export const deleteEntityContactXref = async(
	host: string,
	accessToken: string,
	entityContactXrefData: DeleteEntityContactXrefData,
): Promise<void> => {
	const response = await fetch(
		`${host}/entity-contact-xrefs/?entity_id=${entityContactXrefData.entity_id}&entity_contact_id=${entityContactXrefData.entity_contact_id}`,
		{
			method: 'DELETE',
			headers: new Headers( {
				'Authorization': `Bearer ${accessToken}`,
				'content-type': 'application/json',
			} ),
		},
	)

	if ( !response.ok ) {
		throw new Error( `RequestLogicalError:deleteEntityContactXref:${response.status}` )
	}
}

export function createEntityContactXrefMutation(
	host: string,
	accessToken: string,
) {
	return useMutation(
		[ 'createEntityContactXref', host, accessToken ],
		async( entityContactXrefData: EntityContactXrefData ) => await createEntityContactXref( host, accessToken, entityContactXrefData ),
	)
}

export function deleteEntityContactXrefMutation(
	host: string,
	accessToken: string,
) {
	return useMutation(
		[ 'deleteEntityContactXref', host, accessToken ],
		async( entityContactXrefData: EntityContactXrefData ) => await deleteEntityContactXref( host, accessToken, entityContactXrefData ),
	)
}
