import React, { useState } from 'react';
import { AccountTrigger } from '../account-trigger/account-trigger';
import { useUserQuery } from '../../api/api';
import styled from 'styled-components';
import {
	EuiPopover,
	EuiPopoverTitle,
	EuiFlexGroup,
	EuiFlexItem,
	EuiText,
	COLORSLIGHT,
	TYPESIZING,
	EuiPopoverFooter,
} from '@equipmentshare/ds2';

import { AccountLogout } from '../account-logout/account-logout';
import { useEnvironmentConfiguration } from '../../environment-configuration/context';

const CompanyName = styled(EuiText)`
	color: ${COLORSLIGHT.darkShade};
	text-transform: uppercase;
	${TYPESIZING.xs};
`;

export const AccountDrawer = () => {
	const { auth0, clientId, returnTo } = useEnvironmentConfiguration();
	const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);
	const closePopover = () => setIsAccountDrawerOpen(false);
	const onAccountTriggerClick = () =>
		setIsAccountDrawerOpen(
			(prevIsAccountDrawerOpen) => !prevIsAccountDrawerOpen,
		);

	const userQuery = useUserQuery();
	const user = userQuery.data;
	const userDisplayName =
		[user?.first_name, user?.last_name].filter(Boolean).join(' ') ||
		'Unknown User';

	return (
		<EuiPopover
			button={<AccountTrigger user={user} onClick={onAccountTriggerClick} />}
			isOpen={isAccountDrawerOpen}
			closePopover={closePopover}
			panelPaddingSize="none"
			initialFocus={false}
		>
			{user ? (
				<EuiPopoverTitle paddingSize="m">
					<EuiFlexGroup direction="column">
						<EuiFlexItem>
							<EuiText size="m">{userDisplayName}</EuiText>
							{user.company?.name ? (
								<CompanyName>{user.company.name}</CompanyName>
							) : null}
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiPopoverTitle>
			) : null}
			{clientId && returnTo ? (
				<EuiPopoverFooter>
					<AccountLogout
						logout={() => auth0.logout({ returnTo, client_id: clientId })}
					/>
				</EuiPopoverFooter>
			) : null}
		</EuiPopover>
	);
};
