import {
  _EuiThemeColors, _EuiThemeBrandColors, _EuiThemeBrandTextColors, _EuiThemeShadeColors, _EuiThemeTextColors, _EuiThemeConstantColors, _EuiThemeSpecialColors, _EuiThemeColorsMode,
  _EuiThemeBase,
  _EuiThemeSizes,
  _EuiThemeFont,
  _EuiThemeFontScales,
  _EuiThemeFontWeights,
  _EuiThemeBorder,
  _EuiThemeFocus,
  _EuiThemeAnimation,
  _EuiThemeBreakpoints,
  _EuiThemeLevels,
  computed, sizeToPixel,
  EuiThemeShape
} from '@elastic/eui';

// --------------
// Color subtypes

export const ColorsBrand: _EuiThemeBrandColors = {
  primary:  "#006EF3",
  accent: "#F04E98",
  success: "#00CB86",
  warning: "#FCDD6A",
  danger: "#DA344D",
} as const;

export const ColorsBrandText: _EuiThemeBrandTextColors = {
  primaryText: "#0064DB",
  accentText: "#BA3D76",
  successText: "#007A51",
  warningText: "#7B6C34",
  dangerText: "#DA344D",
} as const;

export const ColorsShade: _EuiThemeShadeColors = {
  emptyShade: "#FFF",
  lightestShade: "#F0F4FB",
  lightShade: "#D3DAE6",
  mediumShade: "#98A2B3",
  darkShade: "#69707D",
  darkestShade: "#343741",
  fullShade: "#000"
} as const;

export const ColorsText: _EuiThemeTextColors = {
  text: ColorsShade.darkestShade,
  title: "#1a1c21",
  subdued: "#646a77",
  link: ColorsBrandText.primaryText
} as const;

export const ColorsConstant: _EuiThemeConstantColors = {
  ghost: "#FFF",
  ink: "#000"
} as const;

export const ColorsSpecial: _EuiThemeSpecialColors = {
  body: ColorsConstant.ghost,
  highlight: "#fffcf0",
  disabled: "#ABB4C4",
  disabledText: "#a2abba",
  shadow: ColorsConstant.ink,
} as const;

// ----------
// Build Color Modes

export const COLORSLIGHT: _EuiThemeColorsMode = {
  ...ColorsBrand,
  ...ColorsBrandText,
  ...ColorsShade,
  ...ColorsText,
  ...ColorsSpecial,
  ...ColorsConstant
} as const;

export const COLORSDARK: _EuiThemeColorsMode = {
  // TODO when we go dark mode
  ...COLORSLIGHT
} as const;

// </ Colors
// ----------

export const BASE: _EuiThemeBase = 16;

export const TYPEFEATURES = {
  family: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif",
  familyCode: "'Roboto Mono', Menlo, Courier, monospace",
  featureSettings: "'calt' 1, 'kern' 1, 'liga' 1",
  baseline: 4,
  lineHeightMultiplier: 1.5,
} as const;

export const TYPESCALE: _EuiThemeFontScales = {
  xxxs: 0.5625,
  xxs: 0.6875,
  xs: 0.75,
  s: 0.875,
  m: 1,
  l: 1.25,
  xl: 1.75,
  xxl: 2.125,
} as const;

export const TYPEWEIGHT: _EuiThemeFontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
} as const;

export const TYPESIZING = {
  xxl: {
    fontSize: "34px",
    lineHeight: "48px",
  },
  xl: {
    fontSize: "27px",
    lineHeight: "40px",
  },
  l: {
    fontSize: "22px",
    lineHeight: "32px",
  },
  m: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  s: {
    fontSize: "14px",
    lineHeight: "16px",
  },
  xs: {
    fontSize: "12px",
    lineHeight: "16px",
  },
  base: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  code: {
    fontSize: "14.4px",
    lineHeight: "24px",
    fontFamily: TYPEFEATURES.familyCode,
  },
} as const;

export const TYPE: _EuiThemeFont = {
  ...TYPEFEATURES,
  scale: TYPESCALE,
  weight: TYPEWEIGHT,
  body: {
    scale: "s",
    weight: "regular"
  },
  title: {
    weight: 'bold'
  }
}

export const ANIMATION: _EuiThemeAnimation = {
  extraFast: "90ms",
  fast: "150ms",
  normal: "250ms",
  slow: "350ms",
  extraSlow: "500ms",
  bounce: "cubic-bezier(.34, 1.61, .7, 1)",
  resistance: "cubic-bezier(.694, .0482, .335, 1)",
} as const;

export const BORDER: _EuiThemeBorder = {
  color: "#C4CCD3",
  width: {
    thin: "1px",
    thick: "2px",
  },
  radius: {
    medium: "6px",
    small: "4px",
  },
  thin: "1px solid #C4CCD3",
  thick: "2px solid #C4CCD3",
  editable: "2px dotted #C4CCD3",
} as const;

export const SIZE: _EuiThemeSizes = {
  xxs: "2px",
  xs: "4px",
  s: "8px",
  m: "12px",
  base: "16px",
  l: "24px",
  xl: "34px",
  xxl: "40px",
  xxxl: "48px",
  xxxxl: "64px",
} as const;

export const BREAKPOINT: _EuiThemeBreakpoints = {
  xl: 1200,
  l: 992,
  m: 768,
  s: 575,
  xs: 0,
} as const;

export const FOCUS: _EuiThemeFocus = {
  color: 'currentColor',
  width: computed(sizeToPixel(0.125)),
} as const;

export const LEVELS: _EuiThemeLevels = {
  toast: 9000,
  modal: 8000,
  mask: 6000,
  navigation: 6000,
  menu: 2000,
  header: 1000,
  flyout: 1000,
  maskBelowHeader: 1000,
  content: 0
} as const;

export const THEME_TOKENS: EuiThemeShape = {
  colors: {
    ghost: '#FFF',
    ink: '#000',
    LIGHT: COLORSLIGHT,
    DARK: COLORSDARK
  },
  base: BASE,
  size: SIZE,
  font: TYPE,
  border: BORDER,
  focus: FOCUS,
  animation: ANIMATION,
  breakpoint: BREAKPOINT,
  levels: LEVELS
}