export const createQueryString = ( params: Record<string, number | string | undefined> ) => {
	const filtered = Object.entries( params ).filter( ( param ): param is [string, string | number] => param[ 1 ] !== undefined )

	let queryString = ''

	if ( filtered.length > 0 ) {
		queryString += `?${filtered
			.map( ( [ key, value ] ) => `${key}=${encodeURI( typeof value === 'number' ? value.toString() : value )}` )
			.join( '&' )}`
	}

	return queryString
}
