import React from 'react';
import styled from 'styled-components';
import {
	EuiIcon,
	EuiKeyPadMenuItem,
	SIZE,
	TYPEWEIGHT,
} from '@equipmentshare/ds2';

const GRID_COLUMN_WIDTH = '102px';

const AppDrawerAppItem = styled(EuiKeyPadMenuItem)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: ${GRID_COLUMN_WIDTH};
	height: initial;
	margin: 0;
	padding: ${SIZE.s};

	.euiKeyPadMenuItem__inner {
		justify-content: flex-start;
		height: initial;
	}

	.euiKeyPadMenuItem__label {
		font-weight: ${TYPEWEIGHT.regular};
	}

	&:not(.euiKeyPadMenuItem-isDisabled):hover,
	&:not(.euiKeyPadMenuItem-isDisabled):focus:hover,
	&:not(.euiKeyPadMenuItem-isDisabled):focus-within {
		box-shadow: none;
	}
`;

const AppDrawerAppItemIcon = styled(EuiIcon)`
	width: 52px;
	height: 52px;
	border-radius: 10px;
`;

export type AppDrawerItem = {
	readonly logo_url: string;
	readonly name: string;
	readonly display_name: string;
	readonly unav_application_id: number;
	readonly url: string;
};

export type AppDrawerAppProps = {
	readonly app: AppDrawerItem;
	readonly onClick: (url: string) => void;
};

export const AppDrawerApp = ({ app, onClick }: AppDrawerAppProps) => (
	<AppDrawerAppItem
		label={app.display_name}
		onClick={() => onClick(app.url)}
		role="menuitem"
	>
		<AppDrawerAppItemIcon type={app.logo_url} />
	</AppDrawerAppItem>
);
