import { useEnvironmentConfiguration } from './context';

export const API_ROOT_PROD = 'https://api.equipmentshare.com' as const;
export const API_ROOT_STAGE = 'https://staging-api.equipmentshare.com' as const;

export const useApiRoot = (): string => {
	const { environment } = useEnvironmentConfiguration();

	if (environment === 'production') {
		return API_ROOT_PROD;
	}

	if (environment === 'devenv') {
		return window.location.origin.replace(
			/-[\w]+(?=\.dev\.equipmentshare\.com)/g,
			'',
		);
	}

	return API_ROOT_STAGE;
};
