import React, { useEffect } from "react";
import { deleteStoredUserId } from "../identity/identity-storage";
import { removeMimicryCookies } from "../mimic/mimic-utils";
import { useAuth0 } from "./use-auth0";
import * as GrantCookie from "../identity/identity-grant-cookie";

// How long to display the error message before logging back in as the default user grant
const INACTIVE_USER_GRANT_ERROR_MESSAGE_DISPLAY_TIMEOUT = 5000;

export type ErrorHandlerProps = {
  clientId: string;
};

export const ErrorHandler: React.FC<ErrorHandlerProps> = ({ children, clientId }) => {
  const { error, logout } = useAuth0();
  const isInactiveUserGrantError = error && error.message.startsWith("[IDENTITY-E06]")

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
      client_id: clientId,
    });
  };

  const clearStateAndReauthenticate = () => {
    removeMimicryCookies();
    deleteStoredUserId();
    GrantCookie.remove();

    // Redirect back to the origin page and let Auth0 reauthenticate as the default user grant for this identity
    window.location.href = window.location.origin;
  }

  // When a user grant is no longer active, display the error message until INACTIVE_USER_GRANT_ERROR_MESSAGE_DISPLAY_TIMEOUT expires
  // and then log back in as the default user grant
  useEffect(() => {
    if (isInactiveUserGrantError) {
      const timer = setTimeout(clearStateAndReauthenticate, INACTIVE_USER_GRANT_ERROR_MESSAGE_DISPLAY_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [isInactiveUserGrantError]);

  if (error) {
    return (
      <div>
        <h3>Authentication Error</h3>
        <p>
          {error.name}: {error.message}
        </p>
        {isInactiveUserGrantError && (
          <p>
            Attempting to reauthenticate as your default user in {Math.round(INACTIVE_USER_GRANT_ERROR_MESSAGE_DISPLAY_TIMEOUT/1000)} seconds...
          </p>
        )}
        <button onClick={handleLogout}>Return to login</button>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
